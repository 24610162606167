
@import "./../../basedata/variables";

.loading-wrapper {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 90px;
  margin: 20% auto;

  .loading-label {
    width: 40px;
    text-align: center;
    position: absolute;
    top:  36%;
    left: 30%;
    font-weight: 700;
    color: var(--pm-primary); } }

.lds-ring {
  // display: inline-block
  // position: relative
  // width: 80px
  // height: 80px
  // margin: 20% auto

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 74px;
    height: 74px;
    margin: 8px;
    border: 6px solid var(--pm-primary);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--pm-primary) transparent transparent transparent;

    div:nth-child(1) {
      animation-delay: -0.45s;
      opacity: 0.8; }

    div:nth-child(2) {
      animation-delay: -0.3s;
      opacity: 0.5; }

    div:nth-child(3) {
      animation-delay: -0.15s;
      opacity: 0.3; } } }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }
